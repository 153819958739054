<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to>
            <i class="iconfont icon-fanhui" @click="$router.replace('/grabOrderList2')"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.grabOrderDetail") }}</li>
      </ul>
    </div>
    

    <BetterScroll
      ref="scrollRef"
      @pullingUp="fetchMorePage"
      v-if="alreadyReq"
      class="BetterScroll_1"
    >
      <div class="main main_page">
        <div class="infos" v-if="+(listItems?.[0]?.state) === 3">
          {{  t("common.message.orderTitle") }}
        </div>
        
        <div class="infos" v-if="+(listItems?.[0]?.state) === 1">
          {{   t("common.message.orderTitle1") }} <span class="timer"> {{timeStr}} </span>{{   t("common.message.orderTitle1_1") }}
        </div>
        
        <div class="Order_list">
          <div class="Order_list_box">
            <div class="no_data" v-if="!listItems || listItems.length == 0">
              <p>{{ t("common.message.noRecords") }}{{listItems.length}}</p>
            </div>
            <div
              class="bata"
              :key="index"
              v-for="(item,index) in listItems"
            >
              <ul>
                <li>
                  {{ t("profile.label.time") }}:
                  <b>{{ formatServerDateTime(item.createDate) }}</b>
                </li>
                <li>
                  {{ t("profile.label.serialNumber") }}:
                  <b>{{ item.orderNo }}</b>
                </li>
              </ul>
              <ol>
                <h2><img :src="sysDataDictionaryWbObj.TXHQDZ?.dataContent + item.img" alt="" /></h2>
                <h3>{{ item.productName }}</h3>
                <li>
                  <span>{{ formatMoney(item.price) }}</span>
                  <span class="r">x1</span>
                </li>
                <div class="d_sign01">
                  <br />
                  <span class="sign01">{{  t("common.title.officialGuarantees") }}</span>
                  <span class="r"></span>
                </div>
              </ol>
              <ul style="border:0">
                <li>
                  {{t("common.title.orderStatus")}}
                  
                  <span>{{ +item.state === 2?t("common.title.completed"):(+item.state === 3?t("common.title.freeze"):t("common.title.pendingTransaction")) }}</span>
                </li>
                <li>
                  {{ t("profile.label.commission") }}
                  <span>{{ formatMoney(item.commission) }}</span>
                </li>
                <!-- <li>
                  {{ t("common.title.Paid") }}
                  <span class="c-red">
                    {{ formatMoney(item.paymentAmount) }}
                  </span>
                </li> -->
              </ul>

              <div v-if="+item.state === 1" style="text-align:center;padding: 0 0 8px 0">
                 <router-link to="" @click="payEvent(item.id,item.orderNo,item.price)" class="btn">
                    {{ t("common.title.Excuting") }}
                  </router-link>
              </div>
              
            </div>
          </div>
        </div>

        
      </div>
    </BetterScroll>
  </div>
  <Loading :loading="loading" />
  
  
</template>
<script>
import { ref,reactive,onMounted,watch } from "vue";
import { message } from "ant-design-vue";
import BetterScroll from "@/components/BetterScroll.vue";
import api from "@/api";
import { sysDataDictionaryWbObj } from '@/hooks/sysDataDictionaryWbObj'
import { taskDetailData } from "@/hooks/taskDetail";
import { userDetail } from "@/hooks/userDetail";
import {
  formatMoney,
  formatMoneySign,
  formatServerDateTime,
  formatPercentage,
  formatShortDateTime,
} from "@/utils/formatter";
import { useI18n } from "@/lang";
import Loading from "@/components/Loading.vue";
import { useRouter } from "vue-router";
export default {
  components: { BetterScroll,Loading },
  setup() {
    const router = useRouter();
    const { t, locale } = useI18n();
    const scrollRef = ref(null);
    const listItems = reactive([]);
    const loading = ref(false);
    const alreadyReq = ref(true);
    const currentPage = ref(0);
    const isStopReq = ref(false);
    const cooldownTimer = ref(null);
    const timeStr = ref('');
    const fetchMorePage = () => {
      //预留顶部
    };

    const fetchMorePage2 = () => {
      if (isStopReq.value) {
        return;
      }
      listItems.length = 0
      api
        .queryOrder({
          current: currentPage.value + 1,
          size: 10,
          state: 2
        })
        .then((res) => {
          if (res?.data?.success) {
            alreadyReq.value = true;
            if (+res.data.data?.pages <= +currentPage.value + 1) {
              isStopReq.value = true;
            }
            currentPage.value += 1;
            listItems.push(...res.data.data?.records);
          }
        });
    };

    onMounted(() => {
      let arr1 = []
      arr1.push(taskDetailData.value)
      // let arrItem = arr.filter(item => +item.orderStatus === 1)?arr.filter(item => +item.orderStatus === 1)[0]:null
      // let arr2 = arr.filter(item => +item.orderStatus === 2)
      // listItems.length = 0
      // if(arrItem){
      //   arr1.push(arrItem)
      // }
      listItems.push(...arr1)
      fetchMorePage();
      if(+(listItems?.[0]?.state) === 1){
        let cooldown = listItems?.[0]?.expiraDateTime;
        
        if(+cooldown > 0){
            let cooldownTimer = setInterval(() => {
              let hour = Math.floor((cooldown / 3600) % 24)
              let minute = Math.floor((cooldown / 60) % 60)
              let second = Math.floor(cooldown % 60)
              timeStr.value = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`;
              cooldown--;
              if (+cooldown <= 0) {
                clearInterval(cooldownTimer);
                router.replace("/grabOrderList2");
              }
          }, 1000);
        }
        
      }
    });

    watch(listItems, () => {
      if (scrollRef.value) {
        scrollRef.value.refresh();
      }
    });
    

    function payEvent(id,orderNo,price){
      loading.value = true;
      let formToSend = {
        id: id,
      };
      if(parseFloat(userDetail.value.amount) < parseFloat(price)){
        loading.value = false;
        message.error(t("common.message.SpreadRequired")+':'+parseFloat(userDetail.value.amount - price).toFixed(2));
        // router.replace("/recharge");
        return
      }

      api
        .submitOrder(formToSend)
        .then((res) => {
          loading.value = false;
          if (res?.data?.success) {
            message.success(t("common.title.paySuccess"));
            // router.replace("/grabOrderList2");
            fetchMorePage2()
          }else{
            loading.value = false;
          }

          api.getUserDetails();
        })
        .catch(() => {
          loading.value = false;
        });
    }
    

    return {
      loading,
      payEvent,
      t,
      scrollRef,
      formatMoney,
      formatMoneySign,
      formatPercentage,
      formatServerDateTime,
      formatShortDateTime,
      listItems,
      fetchMorePage,
      alreadyReq,
      sysDataDictionaryWbObj,
      taskDetailData,
      userDetail,
      locale,
      timeStr,
      cooldownTimer,
    };
  },
};
</script>
<style lang="scss" scoped>
.wrap .main {
  position: relative;
  top: 0;
}
.Order_list {
  padding: 0;
}
.scroll-wrapper{
  // top:42px;
  padding-top:42px;
}
.btn{
    display: inline-block;
    width: auto;
    padding: 7px 10px;
    font-size: 14px;
    line-height: normal;
    color: #FFF;
    text-align: center;
    background: #E23D6D;
    border-radius: 28px;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.05);
}
.sign01{
    background: #4caf50;
    display: inline-block;
    padding: 1px 4px;
    color: #fff;
    border-radius: 2px;
}
.d_sign01{
  position: absolute;
  bottom: 5px;
  display: table;
}
.infos{
    background: #fbf8d9;
    padding: 8px;
    margin-top: 4px;
    color: #323233;
    // line-height: .4rem;
}
.timer{
  font-weight: bold;
  color:red;
}
</style>
